import firebase from "../Firebase";
import 'firebase/functions';

const REGION_TOKYO = "asia-northeast1";

export default class CheckToken {

  check(token) {
    return new Promise((resolve, reject) => {
      const onCall = firebase.app().functions(REGION_TOKYO).httpsCallable('entryHostPayeeTokenCall');
      try {
        onCall({token: token})
          .then((result) => {
            resolve(result.data);
          });
      } catch(e) {
        reject(e);
      }
    })
  }
  checkRelation(token) {
    return new Promise((resolve, reject) => {
      const onCall = firebase.app().functions(REGION_TOKYO).httpsCallable('entryHostPayeeRelationTokenCall');
      try {
        onCall({token: token})
          .then((result) => {
            resolve(result.data);
          });
      } catch(e) {
        reject(e);
      }
    })
  }
  checkSharedFamily(token) {
    return new Promise((resolve, reject) => {
      const onCall = firebase.app().functions(REGION_TOKYO).httpsCallable('entryHostPayeeSharedFamilyToolTicketCall');
      try {
        onCall({token: token})
          .then((result) => {
            resolve(result.data);
          });
      } catch(e) {
        reject(e);
      }
    })
  }
}
